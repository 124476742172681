import { enUS, es, ptBR } from "../../i18n";

type TypeInternationalization = "enUS" | "es" | "ptBR";

const showTexts = (internationalization: TypeInternationalization) => {
  const i18n = { enUS, es, ptBR };

  return {
    listSymptonsOne: i18n[internationalization].listSymptonsOne,
    listSymptonsTwo: i18n[internationalization].listSymptonsTwo,
    mobileSubtitle: i18n[internationalization].mobileSubtitle,
    subtitle: i18n[internationalization].subtitle,
    titleQuestion: i18n[internationalization].titleQuestion,
  };
};

export default showTexts;
