import React from "react";

import {
  WhenToUseMobileContainerCard,
  WhenToUseMobileContainerSubtitle,
  WhenToUseMobileContentCard,
  WhenToUseMobileContentListSympton,
  WhenToUseMobileListSymptonDots,
  WhenToUseMobileSpacer,
  WhenToUseMobileSubtitleText,
  WhenToUseMobileTextListSympton,
  WhenToUseMobileTitleText,
} from "./style";

interface IWhenToUseMobile {
  listSymptonsOne: string[];
  listSymptonsTwo: string[];
  titleQuestion: string;
  subtitle: string;
}

export const WhenToUseMobile: React.FunctionComponent<IWhenToUseMobile> = ({
  listSymptonsOne,
  listSymptonsTwo,
  subtitle,
  titleQuestion,
}) => {
  return (
    <WhenToUseMobileContainerCard>
      <WhenToUseMobileContentCard>
        <WhenToUseMobileTitleText text={titleQuestion} />
        <WhenToUseMobileSpacer height={16} />
        <WhenToUseMobileContainerSubtitle>
          <WhenToUseMobileSubtitleText text={subtitle} />
        </WhenToUseMobileContainerSubtitle>
        <WhenToUseMobileSpacer height={50} />
        <WhenToUseMobileListSymptonDots>
          <WhenToUseMobileContentListSympton>
            {listSymptonsOne.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <WhenToUseMobileTextListSympton textItem={item} key={key} />
                </React.Fragment>
              );
            })}
          </WhenToUseMobileContentListSympton>
          <WhenToUseMobileContentListSympton>
            {listSymptonsTwo.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <WhenToUseMobileTextListSympton textItem={item} key={key} />
                </React.Fragment>
              );
            })}
          </WhenToUseMobileContentListSympton>
        </WhenToUseMobileListSymptonDots>
      </WhenToUseMobileContentCard>
    </WhenToUseMobileContainerCard>
  );
};
