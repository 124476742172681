import React from "react";

import { showTexts } from "../../helpers";
import { WhenToUseMobile, WhenToUseWeb } from "./Components";
import { WhenToUseMobileContainer } from "./Components/WhenToUseMobile/style";
import { WhenToUseWebContainer } from "./Components/WhenToUseWeb/style";

interface IWhenToUse {
  internationalization: "enUS" | "es" | "ptBR";
}

export const WhenToUse: React.FunctionComponent<IWhenToUse> = (props) => {
  const showTextsi18n = showTexts(props.internationalization);

  const [width, setWindowWidth] = React.useState(0);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  const showDesktopComponent: boolean = width > 576;

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <React.Fragment>
      {showDesktopComponent ? (
        <WhenToUseWebContainer id="whenToUse">
          <WhenToUseWeb {...showTextsi18n} />
        </WhenToUseWebContainer>
      ) : (
        <WhenToUseMobileContainer id="whenToUse">
          <WhenToUseMobile {...showTextsi18n} />
        </WhenToUseMobileContainer>
      )}
    </React.Fragment>
  );
};
