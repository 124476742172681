import React from "react";

import {
  WhenToUseWebCardImage,
  WhenToUseWebCardTexs,
  WhenToUseWebContainerCard,
  WhenToUseWebContainerCardImage,
  WhenToUseWebContentCard,
  WhenToUseWebContentListSympton,
  WhenToUseWebListSymptonDots,
  WhenToUseWebSpacer,
  WhenToUseWebSubtitleText,
  WhenToUseWebTextListSympton,
  WhenToUseWebTitleText,
} from "./style";

interface IWhenToUseWeb {
  listSymptonsOne: string[];
  listSymptonsTwo: string[];
  titleQuestion: string;
  subtitle: string;
}

export const WhenToUseWeb: React.FunctionComponent<IWhenToUseWeb> = ({
  listSymptonsOne,
  listSymptonsTwo,
  subtitle,
  titleQuestion,
}) => {
  const [width, setWindowWidth] = React.useState(0);

  const Image =
    " https://conecta-assets-prd.telemedicinaeinstein.com.br/images/png/how-to-use-image.png";

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const showDesktopComponent: boolean = width > 960;

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <WhenToUseWebContainerCard>
      {showDesktopComponent && (
        <WhenToUseWebContainerCardImage>
          <div
            style={{
              position: "absolute",
              flex: 1,
              top: 36,
            }}
          >
            <WhenToUseWebCardImage
              source={Image}
              proportion="1-1"
              width="580px"
            />
          </div>
        </WhenToUseWebContainerCardImage>
      )}
      <WhenToUseWebContentCard>
        <WhenToUseWebCardTexs>
          <WhenToUseWebTitleText text={titleQuestion} />
          <WhenToUseWebSpacer height={28} />
          <WhenToUseWebSubtitleText text={subtitle} />
          <WhenToUseWebListSymptonDots>
            <WhenToUseWebContentListSympton>
              {listSymptonsOne.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    <WhenToUseWebTextListSympton textItem={item} key={key} />
                  </React.Fragment>
                );
              })}
            </WhenToUseWebContentListSympton>
            <WhenToUseWebContentListSympton>
              {listSymptonsTwo.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    <WhenToUseWebTextListSympton textItem={item} key={key} />
                  </React.Fragment>
                );
              })}
            </WhenToUseWebContentListSympton>
          </WhenToUseWebListSymptonDots>
        </WhenToUseWebCardTexs>
      </WhenToUseWebContentCard>
    </WhenToUseWebContainerCard>
  );
};
