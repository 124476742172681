import styled from "styled-components";
import { Image, Typography } from "zera";

export const WhenToUseWebSpacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

interface ITypeTextItem {
  textItem: string;
}

export const WhenToUseWebContainerCardImage = styled.div`
  width: 50%;
  display: flex;
`;
export const WhenToUseWebCardImage = styled(Image)``;

export const WhenToUseWebContainer = styled.section`
  width: 100vw;
  justify-content: center;
  background: "#FFFFFF";
  flex-direction: row;
  display: flex;
  position: relative;
`;
export const WhenToUseWebContainerCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 72.22vw;
  height: auto;
  background-color: #0059a4;
  border-radius: 16px;
  margin-top: 96px;
  margin-bottom: 96px;
  max-width: 1040px;
  @media (min-width: 1309.08px) {
    width: 83.22vw;
  }
  @media (min-width: 1152px) {
    width: 114.22vw;
  }
  @media (min-width: 960px) {
    width: 114.22vw;
    height: 412px;
  }
  @media (width: 960px) {
    width: 62.22vw;
    height: 412px;
  }
`;

export const WhenToUseWebContentCard = styled.div`
  width: 50%;
  display: flex;
  height: 230;
  padding: 64px 64px 105px 0px;
  flex-direction: column;
  position: relative;
  left: 5%;
  @media (min-width: 961px) {
    left: 7%;
  }
  @media (max-width: 960px) {
    width: 100%;
    align-items: center;
  }
  @media (max-width: 822.857px) {
    width: 100%;
    align-items: flex-start;
    margin-left: 10%;
  }
`;
export const WhenToUseWebCardTexs = styled.div`
  width: 392px;
  @media (max-width: 960px) {
    width: 419px;
  }
`;

export const WhenToUseWebListSymptonDots = styled.div`
  width: 27.22vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: "relative";
  gap: 24px;
  margin-top: 40px;
  @media (max-width: 960px) {
    width: 46.22vw;
  }
  @media (min-width: 577px) {
    width: 61.64vw;
  }
`;

export const WhenToUseWebTitleText = styled(Typography).attrs(() => ({
  color: "#FFFF",
  fontFamily: "Work Sans",
  fontWeight: "600",
  fontSize: "7",
  lineHeight: "1.2",
}))``;

export const WhenToUseWebSubtitleText = styled(Typography).attrs(() => ({
  color: "#FFFF",
  fontFamily: "Inter",
  fontWeight: "400",
  fontSize: "3",
  lineHeight: "1.5",
}))``;

export const WhenToUseWebContentListSympton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 184px;
  height: 88px;
`;

export const WhenToUseWebTextListSympton = styled(Typography).attrs(
  ({ textItem }: ITypeTextItem) => ({
    text: textItem,
    color: "#FFFF",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "3",
    lineHeight: "1.0",
  })
)<ITypeTextItem>``;
