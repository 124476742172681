import styled from "styled-components";
import { Typography } from "zera";

export const WhenToUseMobileSpacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

interface ITypeTextItem {
  textItem: string;
}
export const WhenToUseMobileContainer = styled.div`
  width: 100vw;
  justify-content: center;
  background: "#FFFFFF";
  flex-direction: row;
  display: flex;
  position: relative;
`;
export const WhenToUseMobileContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90.42vw;
  height: 318px;
  padding: 24px 24px 40px 24px;
  top: 24px;
  left: 0px;
  background-color: #0059a4;
  border-radius: 16px;
  margin-left: 20.5px;
  margin-right: 20.5px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const WhenToUseMobileContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const WhenToUseMobileListSymptonDots = styled.div`
  width: 339px;
  height: 88px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
`;

export const WhenToUseMobileTitleText = styled(Typography).attrs(() => ({
  color: "#FFFF",
  fontFamily: "Work Sans",
  fontWeight: "600",
  fontSize: "7",
  lineHeight: "1.2",
  alignment: "left",
}))``;

export const WhenToUseMobileContainerSubtitle = styled.div`
  min-width: 319px;
`;

export const WhenToUseMobileSubtitleText = styled(Typography).attrs(() => ({
  color: "#FFFF",
  fontFamily: "Inter",
  fontWeight: "200",
  fontSize: "3",
  lineHeight: "1.5",
}))``;

export const WhenToUseMobileContentListSympton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 157.5px;
  height: 88px;
`;

export const WhenToUseMobileTextListSympton = styled(Typography).attrs(
  ({ textItem }: ITypeTextItem) => ({
    text: textItem,
    color: "#FFFF",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "3",
    lineHeight: "1.0",
  })
)<ITypeTextItem>``;
